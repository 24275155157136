import { makeElement, nextFrame } from "../helpers"
import { CalendarTime } from "../time/calendar_time";
import { DateFormatter } from "./date_formatter";

class DatePicker extends HTMLElement {
  static formAssociated = true

  constructor() {
    super()
    this.internals = this.attachInternals()
  }

  connectedCallback() {
    this.parseUrl = this.dataset.parseUrl
    this.changedClass = this.dataset.changedClass

    // data-turbo-permanent handling will result in dups (inconsistent disconnect callbacks invoked)
    this.querySelectorAll("duet-date-picker").forEach((element) => element.remove())

    this.duetPicker = this.createDuetPicker()
    this.duetPicker.addEventListener("duetChange", this.duetPickerChanged)

    this.addEventListener("keydown", this.keyDownPressed)
    this.addEventListener("duetFocus", this.duetPickerFocused)
    this.addEventListener("duetBlur", this.duetPickerBlurred)
    this.addEventListener("duetOpen", this.duetPickerOpened)

    this.setAttribute("initialized", "")
    this.internals.setFormValue(this.value)
    this.originalDate = this.date

    if (this.canParseDate) {
      this.configureParser()
    }
  }

  disconnectedCallback() {
    this.duetPicker.removeEventListener("duetChange", this.duetPickerChanged)
    this.duetPicker.removeEventListener("duetFocus", this.duetPickerFocused)
    this.duetPicker.remove()
    this.removeAttribute("initialized")
  }

  open() {
    this.duetPicker.show()
  }

  keyDownPressed = async (event) => {
    if (event.key == "Escape") {
      event.stopPropagation()
      await nextFrame()
    }
  }

  duetPickerChanged = (event) => {
    if (event.detail.value) {
      this.value = event.detail.value
      this.dispatchDateChangeEvent()
    }
  }

  duetPickerFocused = async () => {
    await this.focus()

    this.input.addEventListener("keydown", this.inputkeyDownPressed)

    if (!this.canParseDate) {
      this.duetPicker.datePickerInput.readOnly = true
    }
  }

  async focus() {
    await nextFrame()

    this.input.focus()
    this.input.select()

    return Promise.resolve()
  }

  duetPickerBlurred = async () => {
    await this.updateWithParsedDate()

    this.input.removeEventListener("keydown", this.inputkeyDownPressed)
  }

  duetPickerOpened = async () => {
    await nextFrame()
    this.autoAdjustDirection()
  }

  inputkeyDownPressed = (event) => {
    switch (event.key) {
      case "ArrowDown":
        this.open()
        break
      case "Enter":
        this.updateWithParsedDate()
        event.preventDefault()
        event.stopPropagation()
        break
    }
  }

  get input() {
    return this.duetPicker.datePickerInput
  }

  get isOpen() {
    return this.duetPicker.open
  }

  get name() {
    return this.getAttribute("name")
  }

  get value() {
    return this.getAttribute("value") || ""
  }

  get canParseDate() {
    return this.parseUrl
  }

  get date() {
    return this.value ? this.duetPicker.dateAdapter.parse(this.value) : null
  }

  reset() {
    this.date = this.originalDate
  }

  set value(value) {
    if (value != null) {
      this.duetPicker.value = value
      this.setAttribute("value", value)
      this.internals.setFormValue(value)
    } else {
      this.duetPicker.value = null
      this.removeAttribute("value")
      this.internals.setFormValue(null)
    }
  }

  set date(date) {
    const originalDate = this.date

    if (!date) this.input.value = "" // Duet fails to clear the value sometimes

    if (originalDate?.getTime() != date?.getTime() && this.isValidDate(date)) {
      this.duetPicker.setValue(date)
    }

    // Duet fails to update the input when the date value doesn't change so we force an input refresh.
    this.input.value = this.formatDate(originalDate)

    if(this.input.value) {
      this.animateChange(this.input)
    }
  }

  get disabled() {
    return this.hasAttribute("disabled")
  }

  set disabled(value) {
    const boolValue = !!value // toggleAttribute second argument only works with booleans

    this.duetPicker.disabled = boolValue
    this.toggleAttribute("disabled", boolValue)
  }

  get required() {
    return this.hasAttribute("required")
  }

  set required(value) {
    this.duetPicker.required = !!value
    this.toggleAttribute("required", !!value)
  }

  get minDate() {
    return this.getAttribute("data-min-date")
  }

  set firstWeekDay(value) {
    this.duetPicker.firstDayOfWeek = value
    this.setAttribute("data-first-week-day", value)
  }

  get firstWeekDay() {
    return this.getAttribute("data-first-week-day") || 0
  }

  set minDate(value) {
    this.duetPicker.min = value
    this.setAttribute("data-min-date", value)
  }

  get maxDate() {
    return this.getAttribute("data-max-date")
  }

  set maxDate(value) {
    this.duetPicker.max = value
    this.setAttribute("data-max-date", value)
  }

  get isOpen() {
    return this.duetPicker.open
  }

  get timePicker() {
    return this.closest("form").querySelector(`#${this.dataset.timePicker}`)
  }

  // Private

  createDuetPicker() {
    const attributes = { "first-day-of-week": this.firstWeekDay }
    attributes.value = this.value || ""

    if (this.id) attributes.identifier = `${this.id}_picker`
    if (this.disabled) attributes.disabled = "disabled"
    if (this.required) attributes.required = "required"
    if (this.maxDate) attributes.max = this.maxDate
    if (this.minDate) attributes.min = this.minDate

    const duetPicker = makeElement("duet-date-picker", attributes)
    duetPicker.dateAdapter.format = this.formatDate
    duetPicker.localization.placeholder = "Choose date…"
    this.appendChild(duetPicker)

    return duetPicker
  }

  autoAdjustDirection() {
    const dimensions = this.duetPicker.getBoundingClientRect()

    const distanceToLeftEdge = dimensions.left
    const distanceToRightEdge = window.innerWidth - dimensions.right

    if (distanceToRightEdge < dimensions.width && distanceToLeftEdge > dimensions.width) {
      this.duetPicker.setAttribute("direction", "left")
    }
  }

  formatDate = (date) => {
    return new DateFormatter(date).formattedDate
  }

  configureParser() {
    // Replace the default handling with our logic to allow free text input. We parse
    // dates on blur.
    //
    // The default behavior will just clear all the characters that are not numbers
    // or hyphens on every key stroke.
    this.duetPicker.handleInputChange = () => {
    }
  }

  async updateWithParsedDate() {
    if (this.hasChangedTextToParse) {
      const [ parsedDate, time ] = await this.parseNaturalDate()

      this.date = parsedDate
      if (time && this.timePicker) this.time = parsedDate

      this.input.dispatchEvent(new Event('input', { bubbles: true }))
    }
    return Promise.resolve()
  }

  get hasChangedTextToParse() {
    return this.formatDate(this.date) != this.input.value;
  }

  async parseNaturalDate() {
    if (!this.hasTextToParse) return [ null, false ]

    const response = await fetch(`${this.parseUrl}/${encodeURIComponent(this.input.value)}`)
    if (response.ok) {
      const jsonResponse = await response.json()
      const { date, time } = jsonResponse
      return [ new Date(parseInt(date) * 1000), time ]
    }
    else
      return [ null, false ]
  }

  set time(date) {
    this.timePicker.setTimeFromDate(date)
    this.animateChange(this.timePicker.input)
  }

  async animateChange(element) {
    if (!this.changedClass) return

    element.classList.remove(this.changedClass)
    await nextFrame()
    element.classList.add(this.changedClass)
  }

  async dispatchDateChangeEvent() {
    this.dispatchEvent(new Event("date-change", { bubbles: true }))
    await nextFrame()
    this.input.dispatchEvent(new Event("input", { bubbles: true }))
  }

  get hasTextToParse() {
    return this.input.value.trim().length > 0
  }

  isValidDate(date) {
    return (!this.minDate || date >= this.parseDate(this.minDate)) &&  (!this.maxDate || date <= this.parseDate(this.maxDate))
  }

  parseDate(dateString) {
    const dateParts = dateString.split("-")
    return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]))
  }
}

window
  .customElements
  .define(
    "date-picker"
    ,
    DatePicker
  )
