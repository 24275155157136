import { CalendarTime } from "./calendar_time";
import { TimeInputController } from "./controller";
import { makeElement } from "../helpers"

class TimePicker extends HTMLElement {
  static formAssociated = true

  constructor() {
    super()
    this.internals = this.attachInternals()
  }

  connectedCallback() {
    this.querySelectorAll("input").forEach((element) => element.remove())
    this.input = createInput(this)
    this.format = this.dataset.format
    this.controller = new TimeInputController(this)
    this.time = this.controller.parseTimeFromInput()
    this.setAttribute("initialized", "")
    this.originalTime = this.time
  }

  disconnectedCallback() {
    this.controller?.destroy()
    this.input.remove()
    this.removeAttribute("initialized")
  }

  get value() {
    return this.getAttribute("value") || ""
  }

  get time() {
    if (this.value) {
      try {
        return CalendarTime.parse(this.value)
      } catch (error) {
        return null
      }
    }

    return null
  }

  set time(time) {
    this.value = time || ""
    const originalValue = this.input.value
    this.input.value = time?.toString({12: this.format == "twelve_hour", short: true}) || ""
    if (this.input.value != originalValue) {
      this.dispatchEvent(new Event("time-change", { bubbles: true }))
    }
  }

  reset() {
    this.time = this.originalTime
  }

  setTimeFromDate(date) {
    this.time = CalendarTime.fromDate(date)
  }

  get disabled() {
    return this.hasAttribute("disabled")
  }

  set disabled(value) {
    const boolValue = !!value // toggleAttribute second argument only works with booleans

    this.input.disabled = boolValue
    this.toggleAttribute("disabled", boolValue)
  }

  // Private

  set value(value) {
    this.internals.setFormValue(value)
    this.setAttribute("value", value)
  }
}

const findOrCreateInput = function(element) {
  const input = element.querySelector("input") || createInput(element)
  input.setAttribute("autocomplete", "off")
  input.setAttribute("autocorrect", "off")
  return input
}

const createInput = function(element) {
  const input = makeElement("input", { type: "text", value: element.value, autocomplete: "off", autocorrect: "off" })
  input.classList.add("input")
  element.appendChild(input)
  return input
}

window.customElements.define("time-picker", TimePicker)


