import { makeElement, nextFrame } from "../helpers"
import { CalendarTime } from "../time/calendar_time";

export class DateFormatter {
  constructor(date) {
    this.date = date
  }

  get formattedDate() {
    return `${this.formattedWeekday}, ${this.formattedMonth}${this.formattedYear}`
  }

  // Private

  get formattedWeekday() {
    if (this.isToday)
      return "Today"
    else if (this.isTomorrow)
      return "Tomorrow"
    else
      return this.weekdayFormatter.format(this.date)
  }

  get isToday() {
    return this.date.toDateString() == new Date().toDateString()
  }

  get isTomorrow() {
    return this.date.toDateString() == new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()
  }

  get weekdayFormatter() {
    return new Intl.DateTimeFormat("en", {
      weekday: "short"
    })
  }

  get formattedMonth() {
    return this.monthFormatter.format(this.date)
  }

  get monthFormatter() {
    return new Intl.DateTimeFormat("en", {
      day: "numeric",
      month: "short"
    })
  }

  get formattedYear() {
    if (this.date.getFullYear() == this.currentYear)
      return ""
    else
      return `, ${this.date.getFullYear()}`
  }

  get currentYear() {
    return new Date().getFullYear()
  }
}
