export function makeElement(tagName, attributes) {
  const element = document.createElement(tagName)
  for (const name in attributes) { const value = attributes[name]; element.setAttribute(name, value) }
  return element
}

export function pad(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return "" + value;
  }
}

export function createEvent(type, properties) {
  const isMouseEvent = [ "click", "mousedown", "mouseup" ].includes(type)
  const event = document.createEvent(isMouseEvent ? "MouseEvent" : "Event")

  event.initEvent(type, true, true)

  for (const key in properties) {
    const value = properties[key]
    event[key] = value
  }
  return event
}

export function nextFrame() {
  return new Promise(requestAnimationFrame)
}

export function triggerEvent(element, type, properties) {
  const event = createEvent(type, properties)
  element.dispatchEvent(event)
  return event
}

export function delay(ms = 1) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
